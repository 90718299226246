var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { ref: "searchbox", on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "휴게시설 비품 목록",
            tableId: "workCheckItem",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            filtering: false,
            columnSetting: false,
            selection: _vm.popupParam.type,
            rowKey: "sopRestFacilitiesFixturesId",
          },
          on: { rowDblclick: _vm.rowDblclick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSELECT", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }